import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./assets/css/index.scss";
import Home from "./components/home-page/home";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Pricing from "./components/pricing-page/pricing";

import FinalCTA from "./components/home-page/final-cta";
import HeroSection from "./components/home-page/hero";
import MadeByPap from "./components/home-page/made-by";
import Mission from "./components/home-page/mission";
const ValueSection = lazy(() => import("./components/home-page/value"));
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <Suspense fallback={<div>Loading ...</div>}>
                <ValueSection />
              </Suspense>
              <MadeByPap />
              <Mission />
              <FinalCTA />
            </>
          }
        />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
