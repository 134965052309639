import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import scrollToTop from "../functions/scroll";
const MadeByPap = () => {
  const [animate_show_case, set_animate_show_case] = useState(false);
  const handleScroll = () => {
    const element = document.querySelector("#showcase");
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top <= window.innerHeight - 300) {
        if (!animate_show_case) {
          set_animate_show_case(true);
        }
      }
    }
  };

  useEffect(() => {
    const element = document.querySelector("#showcase");
    if (element) window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <section
      className={
        animate_show_case
          ? "made-by-pap-section animate-show-case mm-width"
          : "made-by-pap-section mm-width"
      }
      id="showcase"
    >
      <h2 className="section-title">Made by PAP</h2>
      <p className="section-description">
        Navigating the path forward. Effortless app creation, no coding skills
        needed. Together, let's propel your business to new heights.
      </p>
      <Link to="/pricing" className="view-packages-link" onClick={scrollToTop}>
        View Packages
      </Link>
      <div className="all-mades-wrapper">
        <div className="maden-col col-left-1">
          <span className="maden-span m-l-l-t m-animate">
            <img
              loading="lazy"
              src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/left-1-icon.svg"
              alt="Gimini"
              width={54}
              height={56}
            />
            <span className="maden-texts">
              <h3 className="maden-title">Gimini</h3>
              <p className="maden-text">
                Train your
                <br /> users better.
              </p>
            </span>
          </span>
          <img
            src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/left-1.png"
            alt="choose your program"
            width={150}
            height={300}
            className="m-l-l-b m-animate"
          />
        </div>
        <div className="maden-col col-left-2">
          <img
            loading="lazy"
            src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/left-2.png"
            alt="under the mask"
            width={150}
            height={300}
            className="m-l-r-t m-animate"
          />
          <span className="maden-span m-l-r-b m-animate">
            <img
              loading="lazy"
              src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/left-2-icon.svg"
              alt="Mooviz"
              width={54}
              height={56}
            />
            <span className="maden-texts">
              <h3 className="maden-title">Mooviz</h3>
              <p className="maden-text">Express your ideas.</p>
            </span>
          </span>
        </div>
        <div className="maden-col col-middle">
          <img
            loading="lazy"
            src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/middle-img.png"
            alt="made by pap middle"
            width={282}
            height={383}
            className="m-m-m-m  m-animate"
          />
        </div>
        <div className="maden-col col-right-1">
          <span className="maden-span m-r-l-t m-animate">
            <img
              loading="lazy"
              src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/right-1-icon.svg"
              alt="saving smile"
              width={54}
              height={56}
            />
            <span className="maden-texts">
              <h3 className="maden-title">Saving Smile</h3>
              <p className="maden-text">
                Bring back <br /> smiles
              </p>
            </span>
          </span>
          <img
            loading="lazy"
            src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/right-1.png"
            alt="Hope,Happiness,and smiles"
            className="m-r-l-b m-animate"
            width={150}
            height={300}
          />
        </div>
        <div className="maden-col col-right-2">
          <img
            loading="lazy"
            src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/right-2.png"
            alt="Study with us"
            width={150}
            height={300}
            className="m-r-r-t m-animate"
          />
          <span className="maden-span m-r-r-b m-animate">
            <img
              loading="lazy"
              src="https://stpapplatformeastau01.blob.core.windows.net/website/made%20by%20pap/right-2-icon.svg"
              alt="Hope , Happines , Smiles"
              width={54}
              height={54}
            />
            <span className="maden-texts">
              <h3 className="maden-title">My UNI</h3>
              <p className="maden-text">
                Better <br /> education.
              </p>
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default MadeByPap;
