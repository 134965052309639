import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "./hero";
import MadeByPap from "./made-by";
import ValueSection from "./value";

import FinalCTA from "./final-cta";
import Mission from "./mission";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Plan and Publish | Home</title>
      </Helmet>
      <HeroSection />
      <ValueSection />
      <MadeByPap />
      <Mission />
      <FinalCTA />
    </>
  );
};

export default Home;
