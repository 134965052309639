import { Widget } from "@typeform/embed-react";
import React, { useState } from "react";
const TypeFormBtn = () => {
  const [typeForm, setTypeForm] = useState(false);
  return (
    <>
      <button
        className="request-btn"
        onClick={() => {
          setTypeForm(true);
        }}
      >
        Request early access
      </button>
      {typeForm ? (
        <div className="typeform-pop-up-wrapper">
          <button
            className="cross-icon"
            onClick={() => {
              setTypeForm(false);
            }}
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/header%20assets/cross-icon.svg"
              alt="close form"
            />
          </button>
          <Widget
            id="oZ5jEYwK"
            style={{ width: "90%", height: "90%" }}
            className="my-form"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TypeFormBtn;
