import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import handleScroll from "../functions/menu-scroll";
import scrollToTop from "../functions/scroll";
import BurgerMenu from "./burger-menu";
const Header = () => {
  const [menu_items, set_menu_items] = useState([
    {
      id: 1,
      href: "/#overview",
      active: window.location.pathname !== "/pricing",
      text: "Overview",
    },
    {
      id: 2,
      href: "/#features",
      active: false,
      text: "Features",
    },
    {
      id: 3,
      href: "/#showcase",
      active: false,
      text: "Showcase",
    },
    {
      id: 4,
      href: "/#mission",
      active: false,
      text: "Mission",
    },
    {
      id: 5,
      href: "/pricing",
      active: window.location.pathname === "/pricing",
      text: "Pricing",
    },
  ]);
  const [menu, set_menu] = useState(false);
  const active_setter = (id) => {
    const old_menu_items = [...menu_items];
    if (id === 5) {
      old_menu_items[id - 1].active = true;
      old_menu_items[0].active = false;
    } else {
      old_menu_items[4].active = false;
      old_menu_items[0].active = true;
    }
    set_menu_items(old_menu_items);
  };

  return (
    <header className="main-header mm-width">
      <Link to="/" className="main-logo-wrapper" onClick={scrollToTop}>
        <img
          src="https://stpapplatformeastau01.blob.core.windows.net/website/header%20assets/main-logo.svg"
          alt="Plan and Publish"
          width={96}
          height={32}
        />
      </Link>
      <nav className="main-navigation-wrapper">
        <ul className="main-menu-ul">
          {menu_items.map((mi) => (
            <li
              key={mi.id}
              className={mi.active ? "menu-item active" : "menu-item"}
            >
              <Link
                to={mi.href}
                onClick={() => {
                  if (mi.href.includes("#")) {
                    handleScroll(mi.href.replace("/#", ""));
                  }
                  active_setter(mi.id);
                }}
              >
                {mi.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <button
        className="burger-menu-btn"
        onClick={() => {
          set_menu(true);
        }}
      >
        <img
          src="https://stpapplatformeastau01.blob.core.windows.net/website/header%20assets/hamburger-icon.svg"
          alt="menu"
          width={24}
          height={20}
        />
      </button>
      {menu ? (
        <BurgerMenu
          set_menu={set_menu}
          handleScroll={handleScroll}
          menu={menu_items}
        />
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
