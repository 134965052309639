import React from "react";
import TypeFormBtn from "../form/typeform";

const FinalCTA = () => {
  return (
    <section className="final-cta-section">
      <div className="final-message-wrapper">
        <img
          src="https://stpapplatformeastau01.blob.core.windows.net/website/final%20cta/final-cta-img.svg"
          alt=""
          width={47}
          height={48}
        />
        <span className="final-texts-wrapper">
          <h2 className="dont-miss-title">Don’t miss the opportunity.</h2>
          <p className="dont-miss-text">Be among the first to join!</p>
        </span>
      </div>
      <TypeFormBtn />
    </section>
  );
};

export default FinalCTA;
